const contactInfo = {
  en: {
    title: "Contact information",
    description: {
      point1:
        "This questionnaire is intended for the person receiving counseling. Please answer honestly - your answers will help us prepare for your session.",
      point2: ""
    },
    label: {
      name: {
        first: "First name",
        last: "Last name"
      },
      alias: "Alias",
      email: "Email",
      phone: "Phone",
      country: "Country",
      subdivision: "Prefecture",
      registrant: {
        checkbox: "Who is registering for this counseling session?",
        other: "Please specify"
      },
      pregnantWomanAttendance: "Is the expectant mother planning to attend this counseling session?"
    },
    validation: {
      katakana: "Please enter in Katakana",
      phone: "Use only numbers (0-9) and dashes (-)"
    }
  },
  ja: {
    title: "連絡先",
    description: {
      point1:
        "本アンケートには、可能な限りカウンセリングをお受けになるご本人がご回答ください。カウンセリングの内容に影響を与える可能性がありますので、質問への回答はなるべく正確な情報をご記載ください。",
      point2: "カウンセリングを受ける方の連絡先をご入力ください。"
    },
    label: {
      name: {
        first: "名",
        last: "姓"
      },
      alias: "カタカナ",
      email: "メールアドレス",
      phone: "電話番号",
      country: "お住まいの国",
      subdivision: "お住まいの都道府県",
      registrant: {
        checkbox: "このアンケートをご入力いただいている方をご教示ください。",
        other: "具体的にお聞かせください"
      },
      pregnantWomanAttendance: "妊娠前/妊娠中の女性ご本人はカウンセリングに参加しますか。"
    },
    validation: {
      katakana: "カタカナで入力してください",
      phone: "数字(0-9)またはダッシュ(-)のみを使用する"
    }
  }
};

export default contactInfo;
