import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const CONFIGS = {
  production: {
    apiKey: "AIzaSyDvh5idCsA1fK6SDKa_JJSSZNGpe8epJiQ",
    authDomain: "reservation-system-production.firebaseapp.com",
    projectId: "reservation-system-production",
    storageBucket: "reservation-system-production.appspot.com",
    messagingSenderId: "322647587435",
    appId: "1:322647587435:web:fd3dda35fd7cbe94e90f2e"
  },
  staging: {
    apiKey: "AIzaSyBL6eVaQlV9VUI_Sy9bCrlnV33xDzei8Y0",
    authDomain: "reservation-system-6c38d.firebaseapp.com",
    projectId: "reservation-system-6c38d",
    storageBucket: "reservation-system-6c38d.appspot.com",
    messagingSenderId: "863946609964",
    appId: "1:863946609964:web:4b63690069af47b27e4889"
  }
};

const firebaseConfig = CONFIGS[import.meta.env.MODE];

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp, "asia-northeast1");
const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

if (import.meta.env.DEV) {
  // Setup emulators

  connectAuthEmulator(auth, `http://${import.meta.env.VITE_LOCALHOST}:9099`);

  connectFirestoreEmulator(firestore, import.meta.env.VITE_LOCALHOST, 8080);

  connectFunctionsEmulator(functions, import.meta.env.VITE_LOCALHOST, 5001);

  connectStorageEmulator(storage, import.meta.env.VITE_LOCALHOST, 9199);
}

export { firebaseApp, auth, firestore, functions, storage };
