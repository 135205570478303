const fertilityTreatment = {
  en: {
    title: "Fertility treatment history",
    numPastEggCollections:
      "Please enter a number for the approximate number of times you have collected eggs.",
    isPgt: {
      label: "Have you ever received a Preimplantation Genetics Test (PGT)*?",
      placeholder: "Make a selection",
      help: "*PGT is a test to check for chromosomal abnormalities in fertilized eggs."
    },
    numPgt: "Please enter a number for the approximate number of times you have had a PGT.",
    numEmbryoTransfers:
      "Please enter a number for the approximate number of embryo transfers you have had.",
    chromosomeTest: {
      female: {
        label: "Was a chromosomal test performed on the woman undergoing fertility treatment?",
        placeholder: "Make a selection"
      },
      male: {
        label: "Was a chromosomal test performed on the man undergoing fertility treatment?",
        placeholder: "Make a selection"
      },
      results: "What were the results?",
      details: "Please provide details."
    }
  },
  ja: {
    title: "不妊治療歴",
    numPastEggCollections: "これまでに採卵したおよその回数について、数字をご入力ください。",
    isPgt: {
      label: "これまでに着床前検査（PGT）※を受けたことはありますか。",
      placeholder: "選択してください",
      help: "※受精卵の染色体異常の有無などを調べる検査です。"
    },
    numPgt: "これまでにPGTを受けたおよその回数について、数字をご入力ください。",
    numEmbryoTransfers: "これまでに行った胚移植のおよその回数について、数字をご入力ください。",
    chromosomeTest: {
      female: {
        label: "不妊治療を受けている【女性】の染色体検査は実施されましたか。",
        placeholder: "選択してください"
      },
      male: {
        label: "不妊治療を受けている【男性】の染色体検査は実施されましたか。",
        placeholder: "選択してください"
      },
      results: "結果をお聞かせください。",
      details: "結果を具体的にお聞かせください"
    }
  }
};

export default fertilityTreatment;
