const family = {
  en: {
    title: "Family",
    description:
      "Has anyone in your family had a birth defect (congenital condition) or inherited disease? If so, please provide us with their relationship to you and the name of the condition.",
    parent: {
      select: "Parent",
      placeholder: "Make a selection"
    },
    relationship: {
      select: "Relationship",
      placeholder: "Select",
      other: "Please specify"
    },
    geneticDisorders: {
      checkbox: "Condition(s)",
      other: {
        specify: "Please specify",
        help: "ⓘ Please write the names of other conditions here. Separate each condition with a comma (,)."
      }
    },
    button: {
      add: "Add family member"
    },
    noInfo: "No information provided"
  },
  ja: {
    title: "ご家族について",
    description:
      "カップルのご家系内に生まれつきの病気や遺伝性の病気などの方がいらっしゃる場合は、ご予約者様との血縁関係と病名をお聞かせください。カウンセリング時に詳細をお伺いします。",
    parent: {
      select: "女性側の血縁者/パートナー側の血縁者",
      placeholder: "選択してください"
    },
    relationship: {
      select: "血縁関係",
      placeholder: "選択してください",
      other: "具体的にお聞かせください"
    },
    geneticDisorders: {
      checkbox: "病名",
      other: {
        specify: "具体的にお聞かせください",
        help: "ⓘ 具体的にお聞かせください複数ある場合は、指摘されている内容・病気の名称をカンマで区切って下さい。"
      }
    },
    button: {
      add: "家族の追加"
    },
    noInfo: "特記事項なし"
  }
};

export default family;
