const expectations = {
  en: {
    title: "Requests",
    description:
      "What are you hoping to get out of this counseling session? Feel free to share any concerns or questions you have.",
    discussions:
      "Are there any specific concerns that you wish to discuss with your counselor or is there any information that you wish to share? Please describe them here.",
    expectations: {
      select:
        "To ensure we address your needs effectively, please share your expectations for this counseling session.",
      other: "Please specify"
    },
    sentiments: {
      // https://drive.google.com/file/d/1H6X7p_jbO-unLo8cBbtwRLGPppuQATQ5/view
      isDepressed:
        "During the past month, have you often been bothered by feeling down, depressed, or hopeless?",
      isDispleasured:
        "During the past month, have you often been bothered by little interest or pleasure in doing things?"
    },
    reference: {
      select: "How did you learn about us?",
      other: "Please specify"
    }
  },
  ja: {
    title: "ご要望など",
    description: "今回のカウンセリングに対するご要望や現在のお気持ちについてお尋ねします。",
    discussions:
      "今回のカウンセリングで具体的にご相談したい内容や、カウンセリング担当者に伝えておきたいことがございましたら、お聞かせください。",
    expectations: {
      select:
        "今回のカウンセリングに期待することとして、当てはまるものをすべてお選びください。（複数回答可）",
      other: "具体的にお聞かせください"
    },
    sentiments: {
      isDepressed: "この一か月間、気分が沈んだり憂鬱な気持ちになったりすることがよくありましたか。",
      isDispleasured:
        "この一か月間、どうも物事に対して興味がわかない、あるいは心から楽しめない感じがよくありましたか。"
    },
    reference: {
      select: "【出生前検査ホットライン】を何から見聞きして知りましたか",
      other: "具体的にお聞かせください"
    }
  }
};

export default expectations;
