const LANGUAGES = [
  { id: "ja", en: "Japanese", ja: "日本語", loc: "日本語" },
  { id: "en", en: "English", ja: "英語", loc: "English" }
];

function isLocaleSupported(localeId) {
  return LANGUAGES.map((x) => x.id).includes(localeId);
}

export { LANGUAGES, isLocaleSupported };
