const appointment = {
  en: {
    title: "Appointment",
    description: "Please select the desired date and time for your appointment.",
    notification: {
      noAvailability: "No available times",
      loading: {
        point1: "Searching for appointment vacancies...",
        point2: ""
      }
    },
    select: {
      label: "Counselor language"
    },
    label: {
      appointmentId: "ID",
      appointmentDate: "Date",
      time: "Time",
      timezone: "Timezone",
      locale: "Language",
      joinSession: "Session",
    },
    meeting: {
      title: "Join the session",
      button: {
        description: "Click the following button to join the online counseling session at the scheduled time:",
        label: "Join with Google Meet",
      },
      link: {
        description: "Or copy and paste the following link into your browser:",
      }
    }
  },
  ja: {
    title: "予約情報",
    description: "予約の日時を選択してください。",
    notification: {
      noAvailability: "選択可能な予約枠がありません。",
      loading: {
        point1: "予約可能枠を確認中です。",
        point2: "そのままお待ちください。"
      }
    },
    select: {
      label: "カウンセラー言語"
    },
    label: {
      appointmentId: "予約番号",
      appointmentDate: "予約日",
      time: "時間",
      timezone: "タイムゾーン",
      locale: "言語",
      joinSession: "ミーティング",
    },
    meeting: {
      title: "オンラインミーティング",
      button: {
        description: "当日は、ご予約の時間に以下のボタンをクリックしてご参加ください。",
        label: "Google Meetで参加する",
      },
      link: {
        description: "または、以下のリンクをコピーしてブラウザに貼り付けることでもご参加いただけます。",
      }
    }
  }
};

export default appointment;
