const prenatal = {
  en: {
    title: "Prenatal testing",
    description: "",
    pastTests: {
      bool: "Have you had prenatal testing in any of your previous pregnancy(ies)?",
      select: `Please identify test(s) from your previous pregnancy(ies). If you do not remember the name of the test(s), 
      please select 'Other'.`,
      other: {
        input: "Please specify",
        help: "ⓘ Please write the names of other tests here. Separate each test with a comma (,)."
      }
    },
    presentTests: {
      bool: "Have you undergone any prenatal testing for your current pregnancy?",
      select: `Please identify test(s) for your current pregnancy. If you do not remember the name of the test(s), 
      please select 'Other'.`,
      other: {
        input: "Please specify",
        help: "ⓘ Please write the names of other tests here. Separate each test with a comma (,)."
      },
      results: "If you have already received the test results, please describe the results."
    },
    futureTests: {
      bool: "What are your current intentions regarding prenatal testing?",
      select: "Please identify any test(s) that you are considering.",
      other: {
        input: "Please specify",
        help: "ⓘ Please write the names of other tests here. Separate each test with a comma (,)."
      }
    },
    partner: {
      prenatalDiscussion: "Have you discussed prenatal testing with your partner?",
      prenatalTestAgreement: "Do you and your partner agree on prenatal testing?",
      disorderDecision:
        "Together with your partner, have you established a plan of action in the event of a prenatal diagnosis of a fetal condition?"
    }
  },
  ja: {
    title: "出生前検査",
    description: "",
    pastTests: {
      bool: "【以前の妊娠】で出生前検査を受けたことはありますか。",
      select:
        "【以前の妊娠】で受けた出生前検査の名称を具体的にお聞かせください具体的な検査名のご記憶がない場合には、「そのほか」をお選びください。（複数回答可）",
      other: {
        input: "具体的にお聞かせください",
        help: "ⓘ 検査名をお聞かせください。複数ある場合は、各検査名をカンマで区切ってください。"
      }
    },
    presentTests: {
      bool: "【今回の妊娠】で、すでに何らかの出生前検査をお受けになりましたか。",
      select:
        "【今回の妊娠】で受けた出生前検査の名称を具体的にお聞かせください具体的な検査名のご記憶がない場合には、「そのほか」をお選びください。（複数回答可）",
      other: {
        input: "具体的にお聞かせください",
        help: "ⓘ 検査名をお聞かせください。複数ある場合は、各検査名をカンマで区切ってください。"
      },
      results: "すでに検査結果を受け取っている場合には、結果を具体的にお聞かせください"
    },
    futureTests: {
      bool: "出生前検査に対する現在のご意向を教えてください。",
      select:
        "現在ご検討されている出生前検査がございましたら、具体的な検査名をお聞かせください。（複数回答可）",
      other: {
        input: "具体的にお聞かせください",
        help: "ⓘ 検査名をお聞かせください。複数ある場合は、各検査名をカンマで区切ってください。"
      }
    },
    partner: {
      prenatalDiscussion: "パートナーとの間で、出生前検査に関するご相談はされていますか。",
      prenatalTestAgreement: "パートナーとの間で、出生前検査に対するご意向は一致していますか。",
      disorderDecision:
        "妊娠中に赤ちゃんの病気が判明したときの対応をパートナーと二人で相談していますか。"
    }
  }
};

export default prenatal;
