const profile = {
  en: {
    title: "Profile",
    description: "Please provide the date of births for both partners.",
    label: {
      dob: {
        female: "Expectant mother's date of birth",
        partner: "Partner's date of birth"
      }
    },
    help: {
      desktop: "",
      mobile: ""
    },
    noDob: "Not provided"
  },
  ja: {
    title: "プロフィール",
    description: "カップルの生年月日をご入力ください。",
    label: {
      dob: {
        female: "女性の生年月日",
        partner: "パートナーの生年月日"
      }
    },
    help: {
      desktop:
        "ⓘ カレンダーマークが表示されている場合は、カレンダーマークを選択の上で、左上の「年月」をクリックして年月を選択することができます。カレンダーマークが表示されない場合は、生年月日を直接ご入力ください。",
      mobile: "ⓘ カレンダーの左上に表示されている「年」をタップして年を選択することができます。"
    },
    noDob: "なし"
  }
};

export default profile;
