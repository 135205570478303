import appointment from "@/common/locale/forms-questions/appointment";
import clinicId from "@/common/locale/forms-questions/clinic-id";
import contactInfo from "@/common/locale/forms-questions/contact-info";
import expectations from "@/common/locale/forms-questions/expectations";
import family from "@/common/locale/forms-questions/family";
import fertilityTreatment from "@/common/locale/forms-questions/fertility-treatment";
import paymentConfirmed from "@/common/locale/forms-questions/payment-confirmed";
import pregnancyCurrent from "@/common/locale/forms-questions/pregnancy-current";
import pregnancyHistory from "@/common/locale/forms-questions/pregnancy-history";
import prenatalTesting from "@/common/locale/forms-questions/prenatal-testing";
import profile from "@/common/locale/forms-questions/profile";
import ovus from "@/common/locale/forms-questions/ovus";

const ja = {
  layout: {
    confirmation: {
      return: "サインインに戻る"
    },
    booking: {
      button: {
        goBack: "戻る"
      }
    }
  },
  appointment: appointment["ja"],
  clinicId: clinicId["ja"],
  checkout: {
    title: "ご予約を確定する"
  },
  confirmationAuthLink: {
    title: "メールをご確認ください",
    description: {
      point1: "認証メールが送信されました：",
      point2: "メールをご確認の上で、ご予約のお手続きをお願いします。"
    }
  },
  confirmationContact: {
    title: "メッセージが配信されました",
    description:
      "お問い合わせを受け付けました。 担当者よりご連絡いたしますので今しばらくお待ちください。"
  },
  confirmed: {
    title: "ご予約が確定しました",
    description: "登録メールアドレスに予約情報が送信されました。",
    return: "サインインに戻る"
  },
  contactInfo: contactInfo["ja"],
  contactUs: {
    image: {
      alt: "PDnaviのロゴ"
    },
    title: "Contact us",
    description:
      "ご予約完了前後のご質問、ご予約の変更・キャンセルなどは、こちらのフォームよりお問い合わせください。",
    appointmentId: {
      label: "予約番号",
      help: "ⓘ 予約番号をお聞かせいただけますと、お問い合わせ内容をカウンセリング担当者に転送いたしますので、より迅速に対応することができます。"
    },
    name: "お名前",
    email: "メールアドレス",
    message: {
      label: "メッセージ",
      placeholder: "メッセージを入力してください"
    },
    button: "メッセージを送信"
  },
  error: {
    title: "システムエラー",
    description: "システムエラー　ホームに戻る。"
  },
  expectations: expectations["ja"],
  family: family["ja"],
  fertilityTreatment: fertilityTreatment["ja"],
  fourZeroFour: {
    title: "ページが見つかりません",
    description: "申し訳ありませんが、お探しのページが見つかりませんでした。"
  },
  login: {
    email: "メールアドレス",
    button: "続ける",
    error: {
      invalidEmail: "無効なメールアドレス",
      expiredAuthLink: "リンクの期限が切れています。{startNewSession}からサインインしてください。",
      startNewSession: "こちら"
    },
    logo: {
      alt: "株式会社PDnavi"
    },
    notification: {
      reEnterEmail: "メールアドレスを再入力してください。"
    }
  },
  ovus: ovus["ja"],
  patientId: {
    label: "診察券番号",
    validation: {
      matches: "無効な診察券番号"
    },
    help: "ⓘ 半角英数字でご入力ください。アルファベットの大文字、小文字は区別されます。"
  },
  paymentConfirmed: paymentConfirmed["ja"],
  pregnancyCurrent: pregnancyCurrent["ja"],
  pregnancyHistory: pregnancyHistory["ja"],
  prenatalTesting: prenatalTesting["ja"],
  profile: profile["ja"],
  review: {
    title: "入力内容の確認",
    description: {
      important: "まだ予約は完了していません",
      point1: "以下の情報が正しいことをご確認ください。",
      point2:
        "修正が必要な場合は「戻る」を押して修正してください。情報が正しい場合は利用規約にご同意のうえ、「送信」ボタンを押して予約リクエストをしてください。",
      point3:
        "当日中に予約を取得しなかった場合、予約枠を含む一部の情報が失われる可能性がありますのでご注意ください。"
    },
    healthCareProvider: {
      title: "医療機関",
      label: {
        name: "医療機関名",
        patientId: "診察券番号"
      }
    },
    contactInfo: {
      title: contactInfo["ja"].title,
      label: {
        name: "お名前",
        email: contactInfo["ja"].label.email,
        phone: contactInfo["ja"].label.phone,
        location: "所在地"
      }
    },
    registrant: {
      title: "登録者",
      label: {
        email: "メールアドレス"
      },
      whoIs: "このアンケートをご入力いただいている方をご教示ください。",
      pregnantWomanAttendance: "妊娠前/妊娠中の女性ご本人はカウンセリングに参加しますか。"
    },
    tos: {
      title: "利用規約",
      error: "予約をする前に利用規約に同意する必要があります",
      label: "利用規約",
      iFullyAgree: "{tos}を読み、同意します。",
      ovus: "弊社は、お客様に対してカウンセリングサービスを提供するために、医療機関およびその委託を受けた{ovus}社より、お客様の着床前検査の結果（以下「検査結果」といいます）の開示を受けることになります。弊社は、法令・ガイドラインおよび弊社{privacyPolicy}を遵守して、検査結果を適正に取り扱います。",
      pdnavi:
        "本サービスは、{tos}を遵守するお客様にのみご利用いただくことができます。ご入力いただいた個人情報は、{privacyPolicy}にしたがって適正に取り扱います。",
      link: {
        ovus: "OVUS",
        privacyPolicy: "プライバシーポリシー"
      }
    },
    button: "送信"
  },
  footer: {
    link: {
      contact: "お問い合わせ",
      privacyPolicy: "プライバシーポリシー",
      termsOfService: "利用規約"
    },
    copyright: "株式会社PDnavi 著作権所有"
  },
  answer: {
    yes: "はい",
    no: "いいえ",
    none: "なし"
  },
  banners: {
    autoSaveExpiry:
      "ご入力いただいた情報は自動的に保存されますが、当日中に予約を取得しなかった場合、予約枠を含む一部の情報が失われる可能性がありますのでご注意ください。"
  },
  buttons: {
    deleteAccount: "アカウントの削除",
    logout: "サインアウト",
    returnToLogin: "サインインに戻る",
    saveAndContinue: "保存して続行"
  },
  function: {
    fullName: "{lastName} {firstName}"
  },
  head: {
    title: "PDnavi予約システム"
  },
  modal: {
    cancelAppointment: {
      title: "予約のキャンセル",
      description: {
        confirm:
          "「はい」をクリックすると、予約はキャンセルされ、すぐにシステムからログアウトされます。確認メールが送信されます：",
        failure:
          "予約のキャンセル中にエラーが発生しました。もう一度お試しいただくか、{contactUs}からお問い合わせください。",
        failureContactUs: "お問い合わせ"
      },
      button: {
        cancel: "キャンセル",
        signout: "サインアウト",
        yes: "はい",
        no: "いいえ"
      }
    },
    inactivity: {
      title: "セッションタイムアウト警告",
      description: "非アクティブのため、セッションは{sec}秒で自動的に終了します。",
      button: "セッションを続ける"
    },
    notConnected: {
      title: "インターネット接続なし",
      description: "インターネット接続を確認して、もう一度お試しください。",
      button: "リトライ"
    }
  },
  notification: {
    returnHome: "{sec} 秒後に自動的にホーム画面に戻ります。"
  },
  service: {
    prenatal: "出生前検査ホットライン",
    pgt: "着床前検査ホットライン",
    cancer: "がんと遺伝ホットライン"
  },
  validationMessage: {
    alphanumeric: "半角英数字のみ"
  }
};

export default ja;
