import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { getAuth, signOut } from "firebase/auth";

import { sendEmailAdmin } from "@/firebase/functions";
import { useAppointmentStore } from "@/stores/appointment";

/**
 * Send error message to web developer
 * @param {*} error Error object
 */
export async function handleError(error) {
  const route = useRoute();

  console.error(error);
  await sendEmailAdmin({
    timestamp: new Date(),
    userAgent: window.navigator.userAgent,
    locale: navigator.language,
    route: route?.fullPath ?? "",
    ...error
  });
}

/**
 * Async sleep
 * @param {Number} ms Milliseconds
 * @returns
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Checks if this is a valid date
 * @param {String} date Date string
 * @return {Boolean} true if valid date; false otherwise
 */
export function isDate(date) {
  const d = new Date(date);
  return d instanceof Date && !isNaN(d.valueOf());
}

/**
 * Checks if this is a mobile device
 * @returns {Boolean} true if mobile device; false otherwise
 */
export function isMobile() {
  // https://medium.com/geekculture/detecting-mobile-vs-desktop-browsers-in-javascript-ad46e8d23ce5
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}

/**
 * Converts full-width characters to half-width characters
 * @param {string} str - String containing full-width characters
 * @returns {string} - String with full-width characters converted to half-width
 */
export function convertFullWidthToHalfWidth(str) {
  return str
    .replace(/[\uFF01-\uFF5E]/g, function (ch) {
      return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
    })
    .replace(/\u3000/g, " ");
}

/**
 * Logout user and send to services page
 */
export async function logout() {
  const store = useAppointmentStore();
  store.$reset();
  await signOut(getAuth());
}

/**
 * Get time zone identifier
 * @returns Time zone string
 */
export function getTimeZoneId() {
  const store = useAppointmentStore();
  const { appointment } = storeToRefs(store);

  let timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert generic time zone identifier to {continent}/{city} identifier
  if (
    timeZoneId.indexOf("Etc/GMT-9") >= 0 &&
    appointment.value?.contact?.address?.country?.id === "jp"
  ) {
    timeZoneId = "Asia/Tokyo";
  }

  return timeZoneId;
}

/**
 * Get time zone string
 * @returns Time zone string
 */
export function getTimeZoneString() {
  const store = useAppointmentStore();
  const { locale } = storeToRefs(store);

  return new Intl.DateTimeFormat(locale.value, {
    timeZoneName: "long",
    timeZone: getTimeZoneId()
  })
    .format(new Date())
    .split(" ")
    .slice(1)
    .join(" ");
}

/**
 * Removes keys with undefined values recursively
 */
export function removeUndefinedKeys(obj) {
  if (obj && typeof obj === "object" && !Array.isArray(obj)) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => [key, removeUndefinedKeys(value)])
    );
  }
  return obj;
}

/**
 * Convert date string to date object
 * @param {*} dateStr Date string (YYYY-MM-DD)
 * @returns Date object
 */
export function dateStrToObj(dateStr) {
  const dateComponents = dateStr.split("-");
  const year = dateComponents[0];
  const month = dateComponents[1] - 1; // month is 0-indexed
  const day = dateComponents[2];
  return new Date(year, month, day);
}
