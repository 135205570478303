import appointment from "@/common/locale/forms-questions/appointment";
import clinicId from "@/common/locale/forms-questions/clinic-id";
import contactInfo from "@/common/locale/forms-questions/contact-info";
import expectations from "@/common/locale/forms-questions/expectations";
import family from "@/common/locale/forms-questions/family";
import fertilityTreatment from "@/common/locale/forms-questions/fertility-treatment";
import paymentConfirmed from "@/common/locale/forms-questions/payment-confirmed";
import pregnancyCurrent from "@/common/locale/forms-questions/pregnancy-current";
import pregnancyHistory from "@/common/locale/forms-questions/pregnancy-history";
import prenatalTesting from "@/common/locale/forms-questions/prenatal-testing";
import profile from "@/common/locale/forms-questions/profile";
import ovus from "@/common/locale/forms-questions/ovus";

const en = {
  layout: {
    confirmation: {
      return: "Return to sign-in"
    },
    booking: {
      button: {
        goBack: "Go back"
      }
    }
  },
  appointment: appointment["en"],
  clinicId: clinicId["en"],
  checkout: {
    title: "Complete your booking"
  },
  confirmationAuthLink: {
    title: "A verification email has been sent",
    description: {
      point1: "A verification email was sent to",
      point2: "Please check your email to proceed with signing in."
    },
    return: "Return to sign-in"
  },
  confirmationContact: {
    title: "Message delivered",
    description: "Your message has been received. We will be in touch shortly."
  },
  confirmed: {
    title: "Appointment confirmed",
    description:
      "A confirmation email containing your appointment details has been sent to your registered email address for your reference. You may also sign in at any time to review this information."
  },
  contactInfo: contactInfo["en"],
  contactUs: {
    image: {
      alt: "PDnavi logo"
    },
    title: "Contact us",
    description:
      "For inquiries regarding reservations, including pre-booking questions, changes, or cancellations, kindly utilize this form.",
    appointmentId: {
      label: "Appointment ID",
      help: "ⓘ Including your appointment ID allows us to direct your inquiry to the appropriate counseling staff, facilitating a swifter response."
    },
    name: "Name",
    email: "Email",
    message: {
      label: "Message",
      placeholder: "Type your message here"
    },
    button: "Send message"
  },
  error: {
    title: "System error",
    description:
      "We apologize for the inconvenience. An unexpected error has occurred. Our team is investigating the issue."
  },
  expectations: expectations["en"],
  family: family["en"],
  fertilityTreatment: fertilityTreatment["en"],
  fourZeroFour: {
    title: "Page not found",
    description:
      "We apologize for the inconvenience, but the page you requested could not be located."
  },
  login: {
    email: "Email",
    button: "Continue",
    error: {
      invalidEmail: "Invalid email address",
      expiredAuthLink: "The authentication link is no longer valid. Please {startNewSession}.",
      startNewSession: "start a new session"
    },
    logo: {
      alt: "PDnavi, Inc."
    },
    notification: {
      reEnterEmail: "Please re-enter your email address."
    }
  },
  ovus: ovus["en"],
  patientId: {
    label: "Patient ID",
    validation: {
      matches: "Invalid patient ID"
    },
    help: ""
  },
  pregnancyCurrent: pregnancyCurrent["en"],
  pregnancyHistory: pregnancyHistory["en"],
  prenatalTesting: prenatalTesting["en"],
  profile: profile["en"],
  paymentConfirmed: paymentConfirmed["en"],
  review: {
    title: "Review and submit",
    description: {
      important: "Your reservation is not yet confirmed",
      point1: "Please review the information below to ensure it is correct.",
      point2:
        "Review your answers carefully. You can go back and make changes at any time. Once you are satisfied and agree to the terms of service below, click 'Submit' to confirm your reservation.",
      point3:
        "Please be advised that your reservation information, including your appointment slot, may be cancelled unless you confirm your reservation before the end of the day."
    },
    healthCareProvider: {
      title: "Health care provider",
      label: {
        name: "Name",
        patientId: "Patient ID"
      }
    },
    contactInfo: {
      title: contactInfo["en"].title,
      label: {
        name: "Full name",
        email: contactInfo["en"].label.email,
        phone: contactInfo["en"].label.phone,
        location: "Location"
      }
    },
    registrant: {
      title: "Registrant",
      label: {
        email: "Email"
      },
      whoIs: "Who is registering for this counseling session?",
      pregnantWomanAttendance: "Is the expectant mother planning to attend this counseling session?"
    },
    tos: {
      title: "Terms of Service",
      error:
        "To proceed with booking your appointment, please review and agree to the Terms of Service.",
      label: "Terms of Service",
      iFullyAgree: "I have read and agree to the {tos}.",
      ovus: "To provide counseling services, we will obtain pre-implantation test results from your medical institution and {ovus}. We will adhere to all applicable laws, regulations, guidelines, and our {privacyPolicy} to ensure the secure and appropriate handling of this sensitive information.",
      pdnavi:
        "This service can only be used by customers who comply with our {tos}. Any personal information that is entered will be handled in accordance with our {privacyPolicy}.",
      link: {
        ovus: "OVUS",
        privacyPolicy: "Privacy Policy"
      }
    },
    button: "Submit"
  },
  footer: {
    link: {
      contact: "Contact",
      privacyPolicy: "Privacy Policy",
      termsOfService: "Terms of Service"
    },
    copyright: "PDnavi, Inc. All rights reserved."
  },
  answer: {
    yes: "Yes",
    no: "No",
    none: "None"
  },
  banners: {
    autoSaveExpiry:
      "Information entered and saved on this system will only be retained until the end of the day."
  },
  buttons: {
    deleteAccount: "Delete account",
    logout: "Sign out",
    returnToLogin: "Return to login",
    saveAndContinue: "Save and continue"
  },
  function: {
    fullName: "{firstName} {lastName}"
  },
  head: {
    title: "PDnavi Reservation System"
  },
  modal: {
    cancelAppointment: {
      title: "Cancel appointment",
      description: {
        confirm:
          "By clicking 'Yes', your appointment will be canceled and you will immediately be signed out of the system. A confirmation email will be sent to:",
        failure:
          "An error occurred while canceling your appointment. Please try again or {contactUs} for assistance.",
        failureContactUs: "contact us"
      },
      button: {
        cancel: "Cancel",
        signout: "Sign out",
        yes: "Yes",
        no: "No"
      }
    },
    inactivity: {
      title: "Session timeout warning",
      description: "Your session will expire automatically in {sec} seconds due to inactivity.",
      button: "Continue session"
    },
    notConnected: {
      title: "No internet connection",
      description: "Please check your internet connection and try again.",
      button: "Retry"
    }
  },
  notification: {
    returnHome: "Automatically returning to the home screen in {sec} seconds."
  },
  service: {
    prenatal: "Prenatal testing",
    pgt: "Preimplantation genetic testing",
    cancer: "Oncology testing"
  },
  validationMessage: {
    alphanumeric: "Half-width alphanumeric characters only"
  }
};

export default en;
