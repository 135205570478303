import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { createPinia } from "pinia";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import { createHead } from "unhead";
import { getAuth } from "firebase/auth";

import "@/index.css";

import en from "@/locale/en";
import ja from "@/locale/ja";

import datetimeFormats from "@/common/locale/date-time-format";

import App from "@/App.vue";
import router from "@/router";

import VCalendar from "v-calendar";
import "v-calendar/style.css";

import { plugin, defaultConfig } from "@formkit/vue";
import formkitConfig from "@/formkit/config";

// import { handleError } from "@/utils";

const i18n = createI18n({
  legacy: false, // to use Composition API, set to `false`
  messages: { en, ja }, // set locale messages
  locale: "ja", // set active locale
  fallbackLocale: "ja", // set fallback locale
  datetimeFormats
});
const pinia = createPinia();

/**
 * Create a global head instance
 * @see: https://unhead.harlanzw.com/guide/getting-started/setup
 */
createHead();

/**
 * Automatically refresh app when authentication state changes
 */
let app; // Note: this needs to be a global variable
getAuth().onAuthStateChanged(() => {
  console.debug("onAuthStateChanged");
  if (!app) {
    console.debug("Create app");
    app = createApp(App);

    // Handle uncaught errors propagating from withing the application
    // https://vuejs.org/api/application.html#app-config-errorhandler
    // app.config.errorHandler = async (err, instance, info) => {
    //   // handleError({err, instance, info});
    //   console.error("app.config.errorHandler", err, instance, info);
    // };

    app.use(router);
    app.use(autoAnimatePlugin);
    app.use(i18n);
    app.use(VCalendar, {});
    app.use(plugin, defaultConfig(formkitConfig));
    app.use(pinia);
    app.mount("#app");
  }
});

/**
 * Handle load dynamic import errors
 * @see: https://vitejs.dev/guide/build#load-error-handling
 */
window.addEventListener("vite:preloadError", (event) => {
  event.preventDefault(); // prevent default handler
  console.warn("Failed to fetch dynamically imported module", event.payload);
  console.debug("Reloading the page...");
  window.location.reload(); // refresh the page
});
