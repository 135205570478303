const clinicId = {
  en: {
    title: "Code",
    description: "Please enter your health care provider code*",
    hint: "*This code can be found on the back of your PDnavi pamphlet, or contact your health care provider for details.",
    code: {
      label: "Code",
      help: ""
    },
    noCode: {
      text: "If you are not with a health care provider that is supported by PDnavi, please {link}.",
      link: "click here to continue"
    },
    error: "Invalid code"
  },
  ja: {
    title: "医療機関情報",
    description: "通院されている医療機関コードをご入力ください。※",
    hint: "※本コードはパンフレットの裏面に記載されております。詳しくは、通院先の医療機関にお問い合わせ下さい。",
    code: {
      label: "コード",
      help: "ⓘ 半角英数字でご入力ください。アルファベットの大文字、小文字は区別されます。"
    },
    noCode: {
      text: "「出生前検査ホットライン」に対応する医療機関に通院しておらず、医療機関コードをお持ちでない方は、{link}をクリックしてご予約手続きへお進みください。",
      link: "こちら"
    },
    error: "無効なコードです"
  }
};

export default clinicId;
