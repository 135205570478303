/**
 * Alpha numeric rule
 * Unlike Formkit's default, this ignore kanji characters
 *
 * @param {*} node
 * @returns True is value passes rule, False otherwise
 */
const alphanumeric = function (node) {
  const val = node.value.trim();
  return /^[a-zA-Z0-9]+$/.test(val);
};

export default alphanumeric;
