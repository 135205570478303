const paymentConfirmed = {
  en: {
    spinner: {
      point1: "Confirming your payment.",
      point2: "Please wait."
    },
    modal: {
      title: "Payment failed or was canceled",
      description:
        "Unfortunately your payment failed or was canceled. Your appointment has not been confirmed. Please try again or {contactUs} for assistance.",
      contactUs: "contact us",
      button: "Retry"
    }
  },
  ja: {
    spinner: {
      point1: "お支払いを確認中です。",
      point2: "しばらくお待ちください。"
    },
    modal: {
      title: "お支払い手続きが完了しません",
      description:
        "お支払いが失敗したか、キャンセルされました。ご予約は完了していません。もう一度お試しいただくか、{contactUs} までお問い合わせください。",
      contactUs: "お問い合わせ",
      button: "やり直す"
    }
  }
};

export default paymentConfirmed;
