<template>
  <router-view v-slot="{ Component }">
    <Transition>
      <component :is="Component" />
    </Transition>
  </router-view>
</template>

<script setup>
import { watchEffect } from "vue";
import { useI18n } from "vue-i18n";
// import { useHead } from "unhead";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { storeToRefs } from "pinia";

import { changeLocale } from "@formkit/vue";

import { useAppointmentStore } from "@/stores/appointment";

const { locale: i18nLocale } = useI18n({ useScope: "global" });
const store = useAppointmentStore();

const { locale: userLocale } = storeToRefs(store);

/**
 * Add head tags
 * NOTE: Does not work in Production mode
 */
// useHead({
//   meta: [
//     { charset: "utf-8" }, // this isn't loaded correctly, so it's added directly to `index.html`
//     { name: "viewport", content: "width=device-width, initial-scale=1" },
//     { key: "theme-color", name: "theme-color", content: "#ffffff" },
//     { name: "apple-mobile-web-app-title", content: t("head.title") },
//     { name: "application-name", content: t("head.title") },
//     { name: "msapplication-TileColor", content: "#ffffff" },
//     { name: "theme-color", content: "#ffffff" }
//   ],
//   link: [
//     {
//       rel: "apple-touch-icon",
//       sizes: "180x180",
//       href: "/apple-touch-icon.png?v=1"
//     },
//     {
//       rel: "icon",
//       type: "image/png",
//       sizes: "32x32",
//       href: "/favicon-32x32.png?v=1"
//     },
//     {
//       rel: "icon",
//       type: "image/png",
//       sizes: "16x16",
//       href: "/favicon-16x16.png?v=1"
//     },
//     { rel: "manifest", href: "/site.webmanifest?v=1" },
//     { rel: "shortcut icon", href: "/favicon.ico?v=1" }
//   ],
//   title: t("head.title")
// });

/**
 * Automatically change the App's locale when the user changes its locale
 */
watchEffect(() => {
  // Set locale
  if (userLocale.value) {
    // console.debug(userLocale.value);
    document.documentElement.lang = userLocale.value; // dynamically changes the html lang property

    // Set i18n plugin locale
    // https://vue-i18n.intlify.dev/guide/advanced/composition.html#locale-changing
    i18nLocale.value = userLocale.value;

    // Set Formkit plugin locale
    // https://formkit.com/essentials/internationalization
    changeLocale(userLocale.value);
  }
});

/**
 * Listen to user authentication state
 */
onAuthStateChanged(getAuth(), (user) => {
  if (user) {
    // User is signed in
    console.debug("User is signed in", user.email);
    store.init(user.uid);
  } else {
    // User is signed out
    console.debug("User is signed out");
  }
});
</script>

<style>
/* Vue page transitions */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
  overflow: hidden;
  /* Prevents content from overflowing during transition */
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
